<template>
  <div>
    <Table />
  </div>
</template>

<script>
import Table from './systems/Table-comp.vue'
export default {
  components: {
    Table
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top:  0!important;
}
</style>