<template>
  <div class="tableOptions">
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon small class="mr-2 iconeEditar" @click="dialogEdit">
            mdi-pencil
          </v-icon>
        </span>
      </template>
      <span>Editar</span>
    </v-tooltip>

    <v-tooltip bottom color="error">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon class="iconeDeletar" small @click="dialogAPagar = true">
            mdi-delete
          </v-icon>
        </span>
      </template>
      <span>Apagar</span>
    </v-tooltip>

    <v-dialog
      v-model="dialogEditar"
      width="600px"
      class="modelDialog"
      persistent
    >
      <v-card class="card rounded-lg">
        <div class="header-information d-flex justify-space-between">
          <div class="box-information-page">
            <h3>
              Editar dashboard:
              <small class="username-edit">{{ resource.title }}</small>
            </h3>
            <div class="indicator"></div>
          </div>
          <v-btn @click="dialogClear" fab outlined x-small color="red darken-3">
            <v-icon color="red darken-3">mdi-close</v-icon>
          </v-btn>
        </div>

        <v-alert
          v-model="displayError.display"
          type="error"
          close-text="Fechar"
          text
          dense
          dismissible
        >
          {{ displayError.message }}
        </v-alert>

        <v-form @submit.prevent="edit" ref="form" v-model="valid">
          <v-row dense>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="resource.name"
                label="DASHBOARD"
                placeholder="Nome do dashboard (BI)"
                outlined
                dense
                :rules="nameRules"
                counter
                maxlength="255"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="resource.link"
                label="LINK"
                placeholder="Link de acesso ao dashboard (BI)"
                outlined
                dense
                :rules="linkRules"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="resource.initials"
                label="Siglas"
                placeholder="Siglas do sistema"
                outlined
                dense
                :rules="initialsRules"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <v-select
                label="SUPERINTENDÊNCIA"
                placeholder="Solicitante do dashboard (Superintendência)"
                :items="superintendencia"
                item-text="name"
                item-value="id"
                outlined
                dense
                v-model="superintendence_id"
                :rules="superintendenceRules"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <v-file-input
                prepend-icon=""
                dense
                label="IMAGEM"
                placeholder=""
                outlined
                v-model="newIMG"
              ></v-file-input>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <v-select
                label="CATEGORIA"
                placeholder="Categoria do sistema"
                :items="categorias"
                item-text="name"
                item-value="id"
                outlined
                dense
                v-model="atualCategory"
                :rules="categoryRules"
              ></v-select>
            </v-col>

            <v-col cols="12" md="12" sm="12">
              <v-textarea
                label="DESCRIÇÃO"
                placeholder="Descrição do dashboard (BI)"
                outlined
                rows="3"
                row-height="18"
                auto-grow
                v-model="resource.description"
                :rules="descriptionRules"
                counter
                maxlength="400"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-switch
                inset
                v-model="resource.external"
                label="ACESSO EXTERNO?"
              ></v-switch>
            </v-col>

            <v-col cols="12" md="4" sm="4"> </v-col>

            <v-col cols="12" md="2" sm="2" class="d-flex align-center">
              <v-btn block color="success" type="submit"> ENVIAR </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAPagar"
      width="400px"
      class="modelDialog"
      persistent
    >
      <v-card class="card rounded-lg">
        <div class="banner-delete">
          <h4>Deletar Usuário</h4>
        </div>

        <div class="textInfo">
          <h6 class="textDelete">
            Deseja deletar
            <span class="userName">{{ resource.title }}</span> dos dashboards
            cadastrados?
          </h6>
          <h6 class="alertText">(Essa ação não pode ser revertida)</h6>
        </div>

        <v-card-actions class="cardFooter mt-3">
          <v-btn
            class="rounded-lg btn"
            color="#000"
            text
            elevation="0"
            @click="dialogAPagar = false"
          >
            <span>Cancelar</span>
          </v-btn>

          <v-btn
            class="rounded-lg"
            color="#27ae60"
            elevation="0"
            type="submit"
            text
            @click="deletar"
          >
            <span class="textBtn">Confirmar</span>
            <v-icon color="#27ae60" small class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  props: {
    resource: {
      type: Object,
    },
  },

  data() {
    return {
      valid: true,
      dialogEditar: false,
      dialogAPagar: false,
      superintendencia: [],
      superintendence_id: parseInt(this.resource.superintendence_id),
      newIMG: [],
      message: "",
      snackbar: false,
      imageName: this.resource.image,
      atualCategory: parseInt(this.resource.category_id),
      categorias: [
        { id: 1, name: "Administrativo" },
        { id: 2, name: "Assistencial" },
      ],
      nameRules: [(v) => !!v || "Campo obrigatório!"],
      initialsRules: [(v) => !!v || "Campo obrigatório!"],
      linkRules: [(v) => !!v || "Campo obrigatório!"],
      categoryRules: [(v) => !!v || "Campo obrigatório!"],
      superintendenceRules: [(v) => !!v || "Campo obrigatório!"],
      descriptionRules: [(v) => !!v || "Campo obrigatório!"],

      displayError: {
        message: "asd",
        display: false,
      },
    };
  },

  methods: {
    dialogEdit() {
      this.dialogEditar = true;
      this.externo = parseInt(this.resource.private);
      this.oldImg = this.resource.image;

      axios.get(`/superintendencies`).then((response) => {
        this.superintendencia = response.data.data;
      });
    },

    dialogClear() {
      this.dialogEditar = false;
      this.externo = "";
      this.newIMG = [];
      this.imageName = "";
    },

    uppercase() {
      this.resource.name = this.resource.name.toUpperCase();
    },

    edit() {
      if (this.newIMG != "") {
        let formData = new FormData();
        formData.append("image", this.newIMG);
        formData.append("old_image", this.resource.image);
        formData.append("folder", "systems");

        this.imageName = this.newIMG.name;
        axios
          .post(`/files`, formData)
          .then((response) => {
            this.message = response.data.message;
            this.salvarEdicao();
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            this.dialogEditar = true;
          });
      } else {
        this.salvarEdicao();
      }
    },

    salvarEdicao() {
      if (this.$refs.form.validate() == true) {
        this.alterarTipo(this.resource.external);
        let data = new Object();
        data.name = this.resource.name;
        data.initials = this.resource.initials;
        data.description = this.resource.description;
        data.link = this.resource.link;
        data.external = this.externo;
        data.superintendence_id = this.superintendence_id;
        data.image = this.imageName;
        data.category_id = this.atualCategory;

        axios
          .put(`/systems/${this.resource.id}`, data)
          .then((response) => {
            this.dialogEditar = false;
            this.message = response.data.message;
            this.snackbar = true;
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            this.dialogEditar = true;
          });
      }
    },

    alterarTipo(tipo) {
      if (tipo == true) {
        this.externo = 1;
      } else {
        this.externo = 0;
      }
    },

    deletar() {
      this.$emit("eventname", this.resource.id);
    },
  },
};
</script>

<style scoped>
.header-information {
  margin-bottom: 30px;
}

.header-information > .box-information-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header-information > .box-information-page > h3 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
}

.header-information > .box-information-page > .indicator {
  height: 2px;
  width: 50px;
  border-radius: 5px;
  margin-right: 10px;
  background: -webkit-linear-gradient(
    160deg,
    rgb(3, 69, 211) 0%,
    rgb(15, 162, 207) 100%
  );
}

.iconeEditar:hover {
  color: #54c6eb;
}

.iconeDeletar:hover {
  color: #ad2831;
}

.card {
  padding: 20px;
}

.banner-edit {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgba(95, 195, 228, 1) 0%,
    rgba(93, 148, 229, 1) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-delete {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgb(235, 30, 57) 0%,
    rgb(248, 96, 36) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-edit > h4,
.banner-delete > h4 {
  color: #ffffff;
  padding: 10px;
}

.textInfo {
  padding: 15px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 8px;
  text-align: center;
}

.textDelete {
  font-family: "Poppins";
  font-weight: 400;

  font-size: 14px;
}

.userName {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.alertText {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;

  margin-top: 15px;

  color: #f44336;
}

.cardFooter {
  display: flex;
  justify-content: center;
}

.cardFooter {
  padding: 0 !important;
}

.textBtn {
  font-family: "Poppins";
  color: #27ae60;
}

.username-edit {
  font-family: "Poppins";
  font-weight: 500;
  color: #1344a0;
}
</style>
