<template>
  <div>
    <div class="header-information">
      <div class="box-information-page">
        <h3>Sistemas cadastrados</h3>
        <div class="indicator"></div>
      </div>
    </div>

    <v-card class="rounded-lg card-table" elevation="0">
      <section class="header-table">
        <v-text-field
          v-model="search"
          class="rounded-lg"
          append-icon="mdi-magnify"
          label="Pesquisar..."
          single-line
          hide-details
          outlined
          dense
        ></v-text-field>
        <div class="space"></div>
        <v-btn
          class="rounded-lg"
          elevation="0"
          color="primary"
          @click="dialog = true"
        >
          <v-icon small class="mr-2" color="#FFFFFF"> mdi-plus </v-icon>
          <span> Criar sistema </span>
        </v-btn>
      </section>

      <v-data-table
        :headers="headers"
        :items="usuarios"
        :search="search"
        :footer-props="{
          'items-per-page-text': 'Usuários por Página',
        }"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :server-items-length="totalPassengers"
        :loading="loading"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <Crud :resource="item" v-on:eventname="deletarUsuario" />
        </template>

        <template v-slot:[`item.cpf`]="{ item }">
          {{ item.cpf | VMask("###.###.###-##") }}
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" width="650px" persistent>
        <v-card class="create-user-dialog rounded-lg">
          <div class="header-information d-flex justify-space-between">
            <div class="box-information-page">
              <h3>Criar novo sistema</h3>
              <div class="indicator"></div>
            </div>

            <v-btn
              @click="dialog = false"
              fab
              outlined
              x-small
              color="red darken-3"
            >
              <v-icon color="red darken-3">mdi-close</v-icon>
            </v-btn>
          </div>

          <v-alert
            v-model="displayError.display"
            type="error"
            close-text="Fechar"
            text
            dense
            dismissible
          >
            {{ displayError.message }}
          </v-alert>

          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  label="SISTEMA"
                  placeholder="Nome do sistema"
                  outlined
                  dense
                  v-model="name"
                  :rules="nameRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="4">
                <v-text-field
                  label="SIGLAS"
                  placeholder="Siglas do sistema"
                  outlined
                  dense
                  v-model="initials"
                  :rules="initialsRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="4">
                <v-file-input
                  prepend-icon=""
                  dense
                  label="IMAGEM"
                  placeholder=""
                  outlined
                  v-model="image"
                  :rules="imageRules"
                ></v-file-input>
              </v-col>

              <v-col cols="12" md="4" sm="4">
                <v-select
                  label="CATEGORIA"
                  placeholder="Categoria do sistema"
                  :items="categorias"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  v-model="category_id"
                  :rules="categoryRules"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <v-text-field
                  label="LINK"
                  placeholder="Link de acesso ao sistema (Produção)"
                  outlined
                  dense
                  v-model="link"
                  :rules="linkRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <v-select
                  label="SUPERINTENDÊNCIA"
                  placeholder="Solicitante do sistema (Superintendência)"
                  :items="superintendences"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  v-model="superintendence_id"
                  :rules="superintendenceRules"
                ></v-select>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <v-textarea
                  label="DESCRIÇÃO"
                  placeholder="Descrição do sistema"
                  outlined
                  rows="3"
                  row-height="18"
                  auto-grow
                  v-model="description"
                  :rules="descriptionRules"
                ></v-textarea>
              </v-col>

              <v-col cols="12" md="6" sm="4">
                <v-switch
                  inset
                  v-model="external"
                  label="SISTEMA EXTERNO?"
                ></v-switch>
              </v-col>

              <v-col cols="12" md="4" sm="6"> </v-col>

              <v-col cols="12" md="2" sm="2" class="d-flex align-center">
                <v-btn block color="success" @click="createSystem">
                  ENVIAR
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar v-model="snackbar">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import Crud from "./CrudUsers-comp.vue";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      headers: [
        { text: "Nome", align: "start", value: "name" },
        { text: "Iniciais", value: "initials" },
        { text: "Ações", value: "actions", sortable: false },
      ],

      page: 1,
      totalPassengers: 0,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      options: {},

      usuarios: [],
      superintendencia: [],
      message: "",
      snackbar: false,
      loadingBTN: false,

      valid: true,
      categorias: [
        { id: 1, name: "Administrativo" },
        { id: 2, name: "Assistencial" },
      ],

      superintendences: [],

      name: "",
      initials: "",
      image: [],
      category_id: "",
      link: "",
      superintendence_id: "",
      external: false,
      description: "",

      nameRules: [(v) => !!v || "Campo obrigatório!"],
      initialsRules: [(v) => !!v || "Campo obrigatório!"],
      imageRules: [(v) => !!v || "Campo obrigatório!"],
      categoryRules: [(v) => !!v || "Campo obrigatório!"],
      linkRules: [(v) => !!v || "Campo obrigatório!"],
      superintendenceRules: [(v) => !!v || "Campo obrigatório!"],
      descriptionRules: [(v) => !!v || "Campo obrigatório!"],

      displayError: {
        message: "asd",
        display: false,
      },
    };
  },

  components: {
    Crud,
  },

  created() {
    axios // BUSCA SUPERINTENDENCIAS
      .get(`/superintendencies`)
      .then((response) => {
        this.superintendences = response.data.data;
      });
  },

  watch: {
    options: {
      handler() {
        this.indoDataTable();
      },
    },
    deep: true,
  },

  methods: {
    indoDataTable() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;

      axios.get(`/systems?page=${pageNumber}`).then((response) => {
        this.loading = false;
        this.usuarios = response.data.data;
        this.totalPassengers = response.data.total;
        this.numberOfPages = response.data.last_page;
      });
    },

    mounted() {
      this.indoDataTable();
    },

    createSystem() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("image", this.image);
        formData.append("folder", "systems");

        axios
          .post(`/files`, formData)
          .then(() => {
            if (this.external == false) {
              this.external = 0;
            } else {
              this.external = 1;
            }
            let formData = new FormData();
            formData.append("name", this.name);
            formData.append("initials", this.initials);
            formData.append("image", this.image.name);
            formData.append("category_id", this.category_id);
            formData.append("link", this.link);
            formData.append("superintendence_id", this.superintendence_id);
            formData.append("external", this.external);
            formData.append("description", this.description);

            axios.post(`/systems`, formData).then((response) => {
              this.indoDataTable();
              this.dialog = false;
              this.message = response.data.message;
              this.snackbar = true;
            });
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            this.dialog = true;
          });
      }
    },

    deletarUsuario(resource) {
      axios.delete(`/systems/${resource}`).then((response) => {
        this.dialogAPagar = false;
        this.message = response.data.message;
        this.snackbar = true;
      });

      let usuarios = this.usuarios;
      let newArray = usuarios.filter(function (usuarios) {
        return usuarios.id != resource;
      });
      this.usuarios = newArray;
    },
  },
};
</script>

<style scoped>
.header-information {
  margin-bottom: 20px;
}

.header-information > .box-information-page {
  display: flex;
  flex-direction: column;
}

.header-information > .box-information-page > h3 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
}

.header-information > .box-information-page > .indicator {
  height: 2px;
  width: 50px;
  border-radius: 5px;
  margin-right: 10px;
  background: -webkit-linear-gradient(
    160deg,
    rgb(3, 69, 211) 0%,
    rgb(15, 162, 207) 100%
  );
}

.card-table {
  padding: 30px !important;
}

.header-table {
  display: flex;
  margin-bottom: 20px;
}

.space {
  width: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #27ae60;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #27ae60,
    #1abc9c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #27ae60,
    #1abc9c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
